/* eslint-disable react/no-array-index-key */
// This file DOES NOT USE any constant object or external file

import React from 'react'
// import ArrowRight from '@mui/icons-material/ArrowRight';
import { Breadcrumbs, Typography } from '@mui/material'
import { useStyles } from '../../themes'
import { useLocation, useNavigate } from 'react-router-dom'
// import { PAGE_PATH_NAME_TITLE } from '../../constants/constants';
// import { Theme } from '@material-ui/core/styles';

// const useStyles = makeStyles(() => ({
//   container: { padding: 10, width: '100%' },
//   link: {
//     cursor: 'pointer',
//     display: 'flex',
//     alignItems: 'center',
//     gap: 1,
//     fontWeight: 500,
//     margin: 0,
//     padding: 0,
//     fontSize: '14px !important',
//     color: '#007EB4',
//     transition: '0.15s color',
//     '&:hover': {
//       textDecoration: 'none',
//       color: '#3D70B566',
//     },
//   },
//   activeLink: {
//     display: 'flex',
//     alignItems: 'center',
//     gap: 1,
//     fontWeight: 500,
//     fontSize: '14px',
//     margin: 0,
//     padding: 0,
//     color: '#6C7778',
//     cursor: 'text',
//     textDecoration: 'none',
//     '&:hover': {
//       textDecoration: 'none',
//     },
//   },
//   title: {
//     textAlign: 'left',
//     marginTop: '4px',
//     display: 'flex',
//     fontSize: '20px',
//     fontWeight: 'bold',
//   },
// }));

const Breadcrumb: React.FC = () => {
  const classes = useStyles()
  const navigate = useNavigate()
  const location = useLocation()
  const pageTitle = location.pathname
  .split('/')
  .slice(1)
  .slice(-1)[0]
  .split('-')
  .map((element) => element.charAt(0).toUpperCase() + element.slice(1))
  .join(' ')

  return (
    <div>
      <Breadcrumbs className={classes.breadcrumb} aria-label='breadcrumb'>
        <Typography
          className={
            location.pathname !== '/'
              ? classes.breadcrumbLink
              : classes.breadcrumbActiveLink
          }
          role='presentation'
          onClick={() => {
            navigate(`/`)
          }}
        >
          Home
        </Typography>
        {location.pathname.split('/').map((crumb, index) => {
          if(index === location.pathname.split('/').length-1){
            return <div key={index} />;
          }
          if (crumb !== '') {
            if (location.pathname.split('/').slice(-1)[0] === crumb) {
              return (
                <Typography
                  className={classes.breadcrumbLink}
                  key={`breadcrumb_${index}`}
                  role='presentation'
                  onClick={() => {
                    navigate(
                      location.pathname
                        .split('/')
                        .slice(0, index + 1)
                        .join('/')
                    )
                  }}
                >
                  <span
                    className={classes.breadcrumbLink}
                    style={{ textDecoration: 'none !important' }}
                  >
                    {crumb
                      .split('-')
                      .map(
                        (element) =>
                          element.charAt(0).toUpperCase() + element.slice(1)
                      )
                      .join(' ')}
                  </span>
                </Typography>
              )
            }

            return (
              <Typography
                className={classes.breadcrumbLink}
                key={`breadcrumb_${index}`}
                role='presentation'
                onClick={() => {
                  navigate(
                    location.pathname
                      .split('/')
                      .slice(0, index + 1)
                      .join('/')
                  )
                }}
              >
                <span
                  className={classes.breadcrumbLink}
                  style={{ textDecoration: 'none !important' }}
                >
                  {crumb
                    .split('-')
                    .map(
                      (element) =>
                        element.charAt(0).toUpperCase() + element.slice(1)
                    )
                    .join(' ')}
                </span>
              </Typography>
              // <div></div>
            )
          }

          return null
        })}
      </Breadcrumbs>
      <span
        className={classes.breadcrumbTitle}
        style={{
          height: pageTitle === 'Eula' ? '29px' : ''
        }}
      >
        {pageTitle === 'Eula' ? 'EULA' : pageTitle}
      </span>
    </div>
  )
}

export default Breadcrumb
